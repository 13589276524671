import React from 'react';
import PropTypes from 'prop-types';
import FormInput from './formInput';
import CreditCardPayment from './credit-card-payment';
// import WhatIsCodeChampionship from './what-is-code-championship';
import AdditionalLegalDocumentation from './additional-legal-documentation';

const RegistrationForm = (props) => (
  <>
    <p>* indicates the field is required</p>
    <br />
    <FormInput title="First Name" {...props} />
    <FormInput title="Last Name" {...props} />
    <FormInput title="Parent Or Guardian Email" {...props} />
    <FormInput title="Grade" {...props} />
    <FormInput title="School Name" {...props} />
    <FormInput title="School Zip Code" {...props} />
    <FormInput title="Anything Else" {...props} textarea />
    <hr />
    <AdditionalLegalDocumentation />
    <FormInput title="Student Signature" {...props} />
    <FormInput title="Parent Or Guardian Signature" {...props} />
    <hr />
    {props.price > 50 && <CreditCardPayment price={props.price} />}
  </>
);

RegistrationForm.propTypes = {
  price: PropTypes.string,
};

RegistrationForm.defaultProps = {
  price: null,
};

export default RegistrationForm;
