import React from 'react';

import StandardOutboundLink from './standard-outbound-link';

const AdditionalLegalDocumentation = () => (
  <div>
    <p>
      This event will be virtual. We will use video conferencing to host this event.
      The electronic signature below confirms you understand and agree to our
      {' '}
      <StandardOutboundLink href="https://www.codechampionship.com/terms/">Terms of Use</StandardOutboundLink>
      {', '}
      <StandardOutboundLink href="https://www.codechampionship.com/terms/privacy/">Privacy Policy</StandardOutboundLink>
      {', and our '}
      <StandardOutboundLink href="https://www.codechampionship.com/terms/virtual-events/">Virtual Events Policy</StandardOutboundLink>
      .
    </p>
  </div>
);

export default AdditionalLegalDocumentation;
