import React from 'react';
import MailingFormContainer from './mailing-form-container';

const FullEventMessage = () => (
  <div>
    <h2 style={{ color: '#B30300', fontWeight: 'bold' }}>This event is full.</h2>
    <h3>Mailing List</h3>
    <p>
      Be the first to know when registration opens for an event.
      <br />
      We send around one email per month.
    </p>
    <MailingFormContainer />
  </div>
);

export default FullEventMessage;
