import React from 'react';
import PropTypes from 'prop-types';

const RemainingSpots = ({ remainingSpots, totalSpots }) => {
  const eventOpen = remainingSpots > 0;
  const limitedSpotsRemaining = eventOpen && remainingSpots > 0 && remainingSpots < totalSpots / 2;
  return (
    <>
      {eventOpen && (
        <>
          {limitedSpotsRemaining ? (
            <div style={{ color: '#B30300', fontWeight: 'bold' }}>
              {'Only '}
              <strong>{remainingSpots}</strong>
              {` of ${totalSpots} spots remain`}
            </div>
          ) : (
            <div>{`${remainingSpots} spots remaining`}</div>
          )}
        </>
      )}
    </>
  );
};

RemainingSpots.propTypes = {
  remainingSpots: PropTypes.string,
  totalSpots: PropTypes.string,
};

RemainingSpots.defaultProps = {
  remainingSpots: null,
  totalSpots: null,
};

export default RemainingSpots;
