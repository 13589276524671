import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import axios from 'axios';
import RegistrationNameOnlyForm from '../components/registration-name-only-form';
import RegistrationForm from '../components/registration-form';
import Layout from '../components/layout';
import SEO from '../components/seo';
import RemainingSpots from '../components/remaining-spots';
import StripeContainer from '../components/stripe-container';
import FormContainer from '../components/form-container';
import FullEventMessage from '../components/full-event-message';
import LoadingSpinner from '../components/loading-spinner';

import TournamentSeries from '../components/tournament-series';

const determineForm = ({ price, form_type: formType }, allFields, nameOnlyFields) => {
  const isFullForm = formType === 'Full';
  const isPaidEvent = price && price > 50;
  if (isFullForm || isPaidEvent) {
    return { formComponent: RegistrationForm, formModel: allFields };
  }

  // Name Only Form
  return { formComponent: RegistrationNameOnlyForm, formModel: nameOnlyFields };
};

const AllEvents = ({ data }) => {
  const currentEvent = data.specificEvent.edges[0].node;
  const allFields = data.allRegistrationFormFields.edges.map((edge) => edge.node);
  const nameOnlyFields = data.allNameOnlyRegistrationFormFields.edges.map((edge) => edge.node);
  const { formComponent, formModel } = determineForm(currentEvent, allFields, nameOnlyFields);

  const [totalSpots, setTotalSpots] = useState(null);
  const [spacesFilled, setSpacesFilled] = useState(null);
  const [remainingSpacesIsLoaded, setRemainingSpacesIsLoaded] = useState(false);

  const eventId = currentEvent.alternative_id;

  const findOpenSeats = () => {
    axios({
      method: 'GET',
      url: `${process.env.ABAMATH_API_URL}/code-championship/event/seats/${eventId}`,
    }).then((response) => {
      setSpacesFilled(Math.max(response.data.spaces_filled, 0).toString());
      setTotalSpots(Math.max(response.data.max_registrants, 0).toString());
      setRemainingSpacesIsLoaded(true);
    });
  };

  useEffect(findOpenSeats, []);

  const remainingSpots = Math.max(totalSpots - spacesFilled, 0).toString();
  return (
    <Layout>
      <SEO
        title={currentEvent.title}
        description={`Register for the ${currentEvent.title}`}
        keywords={currentEvent.title.split(' ')}
      />
      <h1>
        Register for the
        {' '}
        {currentEvent.title}
      </h1>
      <div className="stripe-hr thin" />
      <RemainingSpots
        remainingSpots={remainingSpots}
        totalSpots={totalSpots}
      />
      <>
        <div className="three-four-ratio-container">
          {currentEvent.title.includes('Tournament Series') && <TournamentSeries price={currentEvent.price} />}
          {remainingSpacesIsLoaded ? (
            <>
              {remainingSpots > 0 ? (
                <StripeContainer>
                  <FormContainer
                    formModel={formModel}
                    postEndpoint="/registrant"
                    afterFormReset={findOpenSeats}
                    supportEmail="support@codechampionship.com"
                    formComponent={formComponent}
                    price={currentEvent.price}
                    eventId={eventId}
                  />
                </StripeContainer>
              ) : <FullEventMessage />}
            </>
          ) : <LoadingSpinner />}
        </div>
      </>
    </Layout>
  );
};

export const query = graphql`
query AllEventQuery($slug: String!) {
  specificEvent: allEventAllEvents(filter: {fields: { slug: { eq: $slug } }}) {
    totalCount
    edges {
      node {
        id
        alternative_id
        title
        start_date
        time
        form_type
        location
        price
        details
        fields {
          slug
        }
      }
    }
  },
  allRegistrationFormFields {
    edges {
      node {
        fieldName
        type
        required
        min
        max
        textarea
      }
    }
  },
  allNameOnlyRegistrationFormFields {
    edges {
      node {
        fieldName
        type
        required
      }
    }
  }
}`;

AllEvents.propTypes = {
  data: PropTypes.shape({
    allRegistrationFormFields: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    allNameOnlyRegistrationFormFields: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    specificEvent: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          alternative_id: PropTypes.string.isRequired,
          price: PropTypes.string,
          title: PropTypes.string.isRequired,
        }),
      })).isRequired,
    }),
  }).isRequired,
};

export default AllEvents;
