import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const noPadding = {
  marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0,
};

const TournamentSeries = ({ price }) => {
  const displayPrice = `$${price / 100}`;

  const deadline = new Date('2024-11-04T00:00:00.000-08:00');
  const today = new Date();
  const date = today > deadline ? today : deadline;
  const dd = date.getDate();
  const yyyy = date.getFullYear();
  const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][date.getMonth()];

  const displayDate = `${month} ${dd}, ${yyyy}`;

  return (
    <div>
      <div className="pricing-panel">
        <div className="panel-title">
          Oct 26 to Nov 16
          <br />
          Saturdays, 9am - 11am
        </div>
        <div className="panel-body">
          <div className="two-wide-container" style={{ padding: '5px' }}>
            <p>
              <s>$215</s>
              <br />
              {displayPrice}
              {' '}
              if registered by
              {' '}
              {displayDate}
            </p>
            <div className="discount-pricing">
              <span className="discount-price">
                {displayPrice}
              </span>
            </div>
          </div>
          <p>
            The Fall 2024 Code Championship Tournament Series is a series
            of four tournaments in four weeks.
            The top point scorers, will advance to the 2024 National Tournament.
            <br />
            <small>
              <Link to="/scoring-details/">Learn more about scoring.</Link>
            </small>
          </p>
          <ul>
            <li>October 26</li>
            <li>November 2</li>
            <li>November 9</li>
            <li>November 16</li>
            <li>November 23 - 2024 National Tournament</li>
          </ul>
          <p>All tournaments will take place from 9am to 11am, United States Central Time.</p>

          <div className="feature-list">
            <div className="feature-item">
              Grades 3 to 9
            </div>
          </div>
          <p style={noPadding}>
            If you can navigate the web, we will guide you through the rest!
          </p>
          <div className="feature-list">
            <div className="feature-item">
              New Coders
            </div>
          </div>
          <p style={noPadding}>
            We will coach you through getting started and help you improve!
          </p>
          <div className="feature-list">
            <div className="feature-item">
              Experienced Coders
            </div>
          </div>
          <p style={noPadding}>
            Play against the best! Winners advance to the National Championship.
          </p>
        </div>
      </div>
    </div>
  );
};

TournamentSeries.propTypes = {
  price: PropTypes.string.isRequired,
};

export default TournamentSeries;
