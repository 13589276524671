import React from 'react';
import PropTypes from 'prop-types';
import { CardElement } from '@stripe/react-stripe-js';
import StandardOutboundLink from './standard-outbound-link';

const CreditCardPayment = ({ price }) => (
  <>
    <h3>{`The cost is $${price / 100}`}</h3>
    <p>
      <span aria-hidden="true">🔒</span>
      {' '}
      We securely process payments through
      {' '}
      <StandardOutboundLink href="https://stripe.com/">Stripe</StandardOutboundLink>
      .
    </p>
    <p>
      The card you enter will be charged $
      {price / 100}
      {' '}
      upon submission.
      This charge is non-refundable.
      A small number of scholarships are available.
      If this cost is prohibitive, please reach out to us
      at scholarships@codechampionship.com
    </p>
    <div className="two-input-row">
      <div className="label-and-input">
        <div className="label-title">
          Credit Card Information
        </div>
        <div>
          <CardElement />
        </div>
      </div>
    </div>
  </>
);

CreditCardPayment.propTypes = {
  price: PropTypes.string.isRequired,
};

export default CreditCardPayment;
